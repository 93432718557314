import get from "lodash/get";
import { array, string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import { getAdSlots } from "../utils";

import "./dfp-component.m.css";

const DfpComponent = ({
  adStyleName,
  containerStyle = "",
  id,
  size,
  path,
  type = "",
  viewPortSizeMapping,
  customStyle = {},
  isSponsored = false,
  isStickyAd = false,
  isSticky = false,
  count = 0,
  centerText = false,
}) => {
  const qtState = useSelector((state) => get(state, ["qt"], {}));
  const adsConfig = get(qtState, ["config", "ads-config", "dfp_ads"], {});
  const loadAdsSynchronously = get(adsConfig, ["load_ads_synchronously"], null);
  const enableAds = get(adsConfig, ["enable_ads"], null);
  const currentPath = get(qtState, ["currentPath"], null);
  const delayAdLoad = get(adsConfig, ["delay_ad_load"], 4);
  const hideAdText = isSponsored || adStyleName === "ad-slot-size-100x60";
  const [hideAd, setHideAd] = useState(false);
  const adRef = useRef(null);

  if (!enableAds) {
    return null;
  }
  const stickyPageAd = isStickyAd ? `ad-slot ${adStyleName} sticky-ad-bottom` : `ad-slot ${adStyleName}`;
  const containerStyleName = containerStyle ? `ad-slot-container ${containerStyle}` : "ad-slot-container";
  const adContainerStyle = adStyleName !== "top-ad-filler" ? "ad-container" : "";

  useEffect(() => {
    getAdSlots({
      path,
      size,
      id,
      qtState,
      type,
      viewPortSizeMapping,
      loadAdsSynchronously,
      delayPeriod: delayAdLoad * 1000,
      count,
      refreshAdUnit: false,
      adsConfig,
    });
  }, [currentPath]);

  const handleCloseClick = () => {
    setHideAd(true);
  };
  return (
    <div styleName={`${containerStyleName}`} style={hideAd ? { display: "none" } : {}} ref={adRef}>
      {isSticky && (
        <div onClick={handleCloseClick} styleName="ad-close">
          <SvgIconHandler type={"close"} width="22" height="22" iconStyle={{ color: "#596E79" }} />
        </div>
      )}
      <div styleName={hideAdText ? `${stickyPageAd}` : `${stickyPageAd} ad-text`} id={id} style={customStyle}></div>
    </div>
  );
};

DfpComponent.propTypes = {
  adStyleName: string,
  id: string,
  size: array,
  path: string,
  type: string,
  viewPortSizeMapping: array,
};

export { DfpComponent };
